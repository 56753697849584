import React from 'react';
import { makeStyles, shorthands, tokens, typographyStyles } from "@fluentui/react-components";

const useClasses = makeStyles({
    
});

const BillingAccountDashboard = () => {
    return (
        <p>Billing Account dashboard coming soon</p>
    )
};

export default BillingAccountDashboard;