import React from 'react';
import {
    Hamburger,
    NavCategory,
    NavCategoryItem,
    NavDrawer,
    NavDrawerBody,
    NavDrawerHeader,
    NavDrawerProps,
    NavItem,
    NavSectionHeader,
    NavSubItem,
    NavSubItemGroup,
    AppItem,
  } from "@fluentui/react-nav-preview";
  import { makeStyles, shorthands, tokens, typographyStyles } from "@fluentui/react-components";

  import {
    Label,
    Radio,
    RadioGroup,
    Switch,
    Tooltip,
    useId,
  } from "@fluentui/react-components";

  import {
    Board20Filled,
    Board20Regular,
    BoxMultiple20Filled,
    BoxMultiple20Regular,
    DataArea20Filled,
    DataArea20Regular,
    DocumentBulletListMultiple20Filled,
    DocumentBulletListMultiple20Regular,
    HeartPulse20Filled,
    HeartPulse20Regular,
    MegaphoneLoud20Filled,
    MegaphoneLoud20Regular,
    NotePin20Filled,
    NotePin20Regular,
    People20Filled,
    People20Regular,
    PeopleStar20Filled,
    PeopleStar20Regular,
    Person20Filled,
    PersonLightbulb20Filled,
    PersonLightbulb20Regular,
    Person20Regular,
    PersonSearch20Filled,
    PersonSearch20Regular,
    PreviewLink20Filled,
    PreviewLink20Regular,
    bundleIcon,
    PersonCircle32Regular,
    PersonCircle24Regular,
  } from "@fluentui/react-icons";

const Person = bundleIcon(Person20Filled, Person20Regular);
const Dashboard = bundleIcon(Board20Filled, Board20Regular);
const Announcements = bundleIcon(MegaphoneLoud20Filled, MegaphoneLoud20Regular);
const EmployeeSpotlight = bundleIcon(
  PersonLightbulb20Filled,
  PersonLightbulb20Regular
);
const Search = bundleIcon(PersonSearch20Filled, PersonSearch20Regular);
const PerformanceReviews = bundleIcon(
  PreviewLink20Filled,
  PreviewLink20Regular
);
const JobPostings = bundleIcon(NotePin20Filled, NotePin20Regular);
const Interviews = bundleIcon(People20Filled, People20Regular);
const HealthPlans = bundleIcon(HeartPulse20Filled, HeartPulse20Regular);
const TrainingPrograms = bundleIcon(BoxMultiple20Filled, BoxMultiple20Regular);
const CareerDevelopment = bundleIcon(PeopleStar20Filled, PeopleStar20Regular);
const Analytics = bundleIcon(DataArea20Filled, DataArea20Regular);
const Reports = bundleIcon(
  DocumentBulletListMultiple20Filled,
  DocumentBulletListMultiple20Regular
);

const useClasses = makeStyles({
    container: {
      display: "flex",
      ...shorthands.gap("5px"),
      height: "100vh",
    },

    drawer: {
        height: "100vh",
    }
  });

const PlaidNav = ({currentPage, setCurrentPage}) => {
    const classes = useClasses();
    const [size, setNavSize] = React.useState("small");
    const [isAppItemIconPresent, setIsAppItemIconPresent] = React.useState(true);
    const appItemIcon = isAppItemIconPresent ? (
        size === "small" ? (
          <PersonCircle24Regular />
        ) : (
          <PersonCircle32Regular />
        )
      ) : undefined;
    const [enabledLinks, setEnabledLinks] = React.useState(true);
    const linkDestination = enabledLinks ? "https://www.bing.com" : "";

    return (
        <NavDrawer
        className={classes.drawer}
        defaultSelectedValue="7"
        defaultSelectedCategoryValue="6"
        open={true}
        type={"inline"}
        size="small"
      >
        <NavDrawerHeader>
          <Label>Plaid Options</Label>
        </NavDrawerHeader>
        <NavDrawerBody>

          <NavItem onClick={setCurrentPage("MainPage")} icon={<Dashboard />} value="1">
            Dashboard
          </NavItem>
          <NavItem
            href="https://www.bing.com"
            icon={<Announcements />}
            value="2"
          >
            Announcements
          </NavItem>
          <NavItem
            href="https://www.bing.com"
            icon={<EmployeeSpotlight />}
            value="3"
          >
            Employee Spotlight
          </NavItem>
          <NavItem icon={<Search />} href="https://www.bing.com" value="4">
            Profile Search
          </NavItem>
          <NavItem
            icon={<PerformanceReviews />}
            href="https://www.bing.com"
            value="5"
          >
            Performance Reviews
          </NavItem>

          <NavSectionHeader>Employee Management</NavSectionHeader>
          <NavCategory value="6">
            <NavCategoryItem icon={<JobPostings />}>
              Job Postings
            </NavCategoryItem>
            <NavSubItemGroup>
              <NavSubItem href="https://www.bing.com" value="7">
                Openings
              </NavSubItem>
              <NavSubItem href="https://www.bing.com" value="8">
                Submissions
              </NavSubItem>
            </NavSubItemGroup>
          </NavCategory>
          <NavItem icon={<Interviews />} value="9">
            Interviews
          </NavItem>
        </NavDrawerBody>
      </NavDrawer>
    )
};

export default PlaidNav;