import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { InternalApiClient } from 'providers/InternalApiClient';
import { makeStyles, tokens, typographyStyles } from "@fluentui/react-components";
import { Button } from "@fluentui/react-components";
import {
    AddFilled,
    DismissRegular,
    PersonMoneyFilled,
  } from "@fluentui/react-icons";
import {
    MessageBar,
    MessageBarActions,
    MessageBarTitle,
    MessageBarBody,
    MessageBarGroup,
  } from "@fluentui/react-components";
import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Input,
    Select,
    Field,
  } from "@fluentui/react-components";
  import {
    Card,
    CardFooter,
    CardHeader,
    CardPreview,
    Text,
    Caption1,
  } from "@fluentui/react-components";
import lightBackground from 'assets/images/backgrounds/visual_background_m365default_light.jpg';
import darkBackground from 'assets/images/backgrounds/visual_background_m365default_dark.jpg';

const useClasses = makeStyles({
    lightBackground: {
        backgroundImage: `url(${lightBackground})`,
        backgroundPosition: "50%",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },
    darkBackground: {
        backgroundImage: `url(${darkBackground})`,
        backgroundPosition: "50%",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },

    headerTitleName: {
        ...typographyStyles.title3,
    },
    headerTitleDescription: {
        ...typographyStyles.body,
    },
    emptyContainer: {
        textAlign: "center",
    },
    messageBarGroup: {
        padding: tokens.spacingHorizontalMNudge,
        display: "flex",
        flexDirection: "column",
        marginTop: "0px",
        gap: "10px",
        height: "40px",
        zIndex: 120,
        overflow: "auto",
        border: "1px solid blue",
    },
    messageBarHidden: {
        display: "none",
    },
    messageBarVisible: {
        display: "block",
    },
    dialogContent: {
        display: "flex",
        flexDirection: "column",
        rowGap: "10px",
    },
    cardsDiv: {
        padding: "10px",
        display: "flex",
        flexWrap: "wrap",
    },
    card: {
        margin: "10px",
        width: "360px",
        maxWidth: "100%",
        height: "fit-content",
        backgroundColor: tokens.colorBrandForegroundInverted,
        ":hover": {
            backgroundColor: tokens.colorBrandForegroundInvertedHover,
        }
    },
    cardIcon: {
        width: "60px",
        height: "60px",
    },
    caption: {
        color: tokens.colorNeutralForeground3,
    },
});

const RatePlanCard = ({ratePlan}) => {
    return (
        <p>{ratePlan.id}</p>
    )
}

const BillingHomePage = ({darkMode}) => {
    const classes = useClasses();
    const [isLoading, setIsLoading] = useState(true);
    const [billingAccounts, setBillingAccounts] = useState([]);
    const [ratePlans, setRatePlans] = useState([]);
    const [finicitySubscriptions, setFinicitySubscriptions] = useState([]);
    const [plaidSubscriptions, setPlaidSubscriptions] = useState([]);
    const [messages, setMessages] = useState([]);
    const clearMessages = () => setMessages([]);
    const dismissMessage = (messageId) =>
        setMessages((s) => s.filter((entry) => entry.id !== messageId));
    const addError = (title, message) => {
        const id = Math.floor(Math.random());
        const newMessage = {
            id: id,
            title: title,
            message: message,
        };

        setMessages((s) => [newMessage, ...s]);
    };
    const [openDialog, setOpenDialog] = useState(false);

    const fetchBillingAccounts = () => {
        InternalApiClient.get("/api/billingaccounts")
        .then((response) => {
            setBillingAccounts(response);
            setIsLoading(false);
        })
        .catch((error) => {
            console.error(error);
            addError("Error Fetching Accounts", error.message);
        });
    };

    const fetchRatePlans = () => {
        InternalApiClient.get("/api/rateplans")
        .then((response) => {
            setRatePlans(response);
        })
        .catch((error) => {
            addError("Error Fetching Rate Plans", error.message);
            console.error(error);
        });
    };

    const fetchFinicitySubscriptions = () => {
        InternalApiClient.get('/api/subscriptions/finicity')
        .then((data) => {
            setFinicitySubscriptions(data);
        })
        .catch((error) => {
            addError("Error Fetching Subscriptions", error.message);
            console.log(error);
        });
    }

    const fetchPlaidSubscriptions = () => {
        InternalApiClient.get('/api/subscriptions/plaid')
        .then((data) => {
            setPlaidSubscriptions(data);
        })
        .catch((error) => {
            addError("Error Fetching Subscriptions", error.message);
            console.log(error);
        });
    }

    useEffect(() => {
        setIsLoading(true);
        clearMessages();
        fetchBillingAccounts();
        fetchRatePlans();
        fetchFinicitySubscriptions();
        fetchPlaidSubscriptions();
    }, []);

    const handleAddSubmit = (ev) =>{
        ev.preventDefault();
        const formData = new FormData(ev.target);
        const formProps = Object.fromEntries(formData);
        console.log(formProps);

        const newBillingAccount = {
            name: formProps.name,
            displayName: formProps.displayname,
            ratePlanId: formProps.rateplan,
            finicitySubscriptionId: formProps.finicity,
            plaidSubscriptionId: formProps.plaid,
        };

        InternalApiClient.post('/api/billingaccounts', newBillingAccount)
        .then((data) => {
            fetchBillingAccounts();
            setOpenDialog(false);
        })
        .catch((error) => {
            addError("Error Adding Rate Plan", error.message);
            console.error(error);
        })
    };

    const getDefaultRatePlan = () => {
        console.log(ratePlans[0]);
        return ratePlans[0].id;
    }

    return (
        <div className={"areaPageContainer "+ (darkMode ? classes.darkBackground : classes.lightBackground)}>
            <div className={"topContainer"}>
                <div className='actionHeaderContainer'>
                    <div className='title hidden-sm hidden-xs'>
                        <div className="fadedSection">
                            <label className={classes.headerTitleName}>Billing Accounts</label>
                            <br/>
                            <label className={classes.headerTitleDescription}>Billing Accounts represent each organization you intend to charge for services. <br/>
                                A single billing account may have multiple tenants but the bills for each tenant roll up to this account.
                            </label>
                        </div>
                    </div>
                    <div className='actions'>
                    <Dialog open={openDialog} onOpenChange={(event, data) => setOpenDialog(data.open)}>
                        <DialogTrigger disableButtonEnhancement>
                            <Button icon={<AddFilled />}>Add New Account</Button>
                        </DialogTrigger>
                        <DialogSurface>
                        <form onSubmit={handleAddSubmit}>
                            <DialogBody>
                                <DialogTitle>Add New Billing Account (and Tenant)</DialogTitle>
                                <DialogContent className={classes.dialogContent}>
                                    <Field required placeholder="Account Name" label="Account Name">
                                        <Input required type="text" name="name" id={"name-input"} />
                                    </Field>
                                    <Field required label="Display Name">
                                        <Input required type="text" name="displayname" id={"displayname-input"} />
                                    </Field>
                                    <Field required label="Initial Tenant's Rate Plan">
                                        <Select placeholder="Select Rate Plan" name="rateplan" id={"rateplan-listbox"}>
                                            {ratePlans.map((ratePlan) => (
                                                <option key={ratePlan.id} value={ratePlan.id}>{ratePlan.name} ({ratePlan.description})</option>
                                            ))}
                                        </Select>
                                    </Field>
                                    <Field required label="Initial Tenant's Finicity Subscription">
                                        <Select placeholder="Select Subscription" name="finicity" id={"finicity-listbox"}>
                                            {finicitySubscriptions.map((subscription) => (
                                                <option key={subscription.id} value={subscription.id}>{subscription.displayName}</option>
                                            ))}
                                        </Select>
                                    </Field>
                                    <Field required label="Initial Tenant's Plaid Subscription">
                                        <Select placeholder="Select Subscription" name="plaid" id={"plaid-listbox"}>
                                            {plaidSubscriptions.map((subscription) => (
                                                <option key={subscription.id} value={subscription.id}>{subscription.displayName}</option>
                                            ))}
                                        </Select>
                                    </Field>
                                </DialogContent>
                                <DialogActions>
                                    <DialogTrigger disableButtonEnhancement>
                                        <Button appearance="secondary">Cancel</Button>
                                    </DialogTrigger>
                                    <Button type="submit" appearance="primary">
                                        Create
                                    </Button>
                                </DialogActions>
                            </DialogBody>
                            </form>
                            </DialogSurface>
                        </Dialog>
                    </div>
                </div>
            </div>
            <div className="bottomContainer">
                <MessageBarGroup classNames={classes.messageBarGroup + (messages.length > 0 ? classes.messageBarVisible : classes.messageBarHidden)}>
                    {messages.map((message) => (
                    <MessageBar key={message.id} intent="error">
                            <MessageBarBody>
                                <MessageBarTitle>{message.title}: </MessageBarTitle>
                                {message.message}
                            </MessageBarBody>
                        <MessageBarActions
                        containerAction={
                            <Button
                            onClick={() => dismissMessage(message.id)}
                            aria-label="dismiss"
                            appearance="transparent"
                            icon={<DismissRegular />}
                            />
                        }
                        />
                    </MessageBar>
                    ))}
                </MessageBarGroup>
                {isLoading &&
                    <p>Loading...</p>
                }
                {!isLoading && billingAccounts.length == 0 &&
                    <div className={classes.emptyContainer}>
                        <p>No Billing Accounts defined</p>
                    </div>
                }
                {!isLoading && billingAccounts.length > 0 &&
                    <div className={classes.cardsDiv}>
                    {billingAccounts.map((account) => (
                        <Link to={"/Billing/" + account.id}>
                        <Card className={classes.card}>
                            <CardHeader
                            image={
                                <PersonMoneyFilled className={classes.cardIcon} />
                            }
                            header={<Text weight="semibold">{account.name}</Text>}
                            description={
                                <Caption1 className={classes.caption}>{account.displayName}</Caption1>
                            }
                            />
                            <p>
                            More info goes here.
                            </p>
                        </Card>
                        </Link>
                    ))}
                    </div>
                }
            </div>
        </div>
    );
};

export default BillingHomePage;