import React, {useState, useEffect} from 'react';
import { InternalApiClient } from 'providers/InternalApiClient';
import { makeStyles, shorthands, tokens, typographyStyles } from "@fluentui/react-components";
import { Button } from "@fluentui/react-components";
import {
    AddFilled
  } from "@fluentui/react-icons";
import lightBackground from 'assets/images/backgrounds/visual_background_m365default_light.jpg';
import darkBackground from 'assets/images/backgrounds/visual_background_m365default_dark.jpg';

const useClasses = makeStyles({
    lightBackground: {
        backgroundImage: `url(${lightBackground})`,
        backgroundPositionX: "center",
        backgroundSize: '200%',
        backgroundRepeat: 'no-repeat',
    },
    darkBackground: {
        backgroundImage: `url(${darkBackground})`,
        backgroundPositionX: "center",
        backgroundSize: '200%',
        backgroundRepeat: 'no-repeat',
    },

    headerTitleName: {
        ...typographyStyles.title3,
    },
    headerTitleDescription: {
        ...typographyStyles.body,
    },
    emptyContainer: {
        textAlign: "center",
    }
});

const RatePlanCard = ({ratePlan}) => {
    return (
        <p>{ratePlan.id}</p>
    )
}

const TenantsHomePage = ({darkMode}) => {
    const classes = useClasses();
    const [tenants, setTenants] = useState([]);

    useEffect(() => {
        InternalApiClient.get("/api/tenants")
        .then((response) => {
            //setRatePlans(response);
            console.log(response);
        });
    }, []);

    return (
        <div className="areaPageContainer">
            <div className={"topContainer" + (darkMode ? classes.darkBackground : classes.lightBackground)}>
                <div className='actionHeaderContainer'>
                    <div className='title hidden-sm hidden-xs'>
                        <div className="fadedSection">
                            <label className={classes.headerTitleName}>Tenants</label>
                            <br/>
                            <label className={classes.headerTitleDescription}>Each tenant is its own entity in the CRM and is treated as an isolated environment. <br/>
                                Activity performed in the tenant is applied to the rate plans to create daily charges, which are applied to Billing Accounts.
                            </label>
                        </div>
                    </div>
                    <div className='actions'>
                    </div>
                </div>
            </div>
            <div className="bottomContainer">
                {tenants.length == 0 &&
                    <div className={classes.emptyContainer}>
                        <p>No Tenants defined</p>
                    </div>
                }
            </div>
        </div>
    );
};

export default TenantsHomePage;