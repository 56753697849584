import React, { useState } from "react";
import { useAuth } from "react-oidc-context";
import {
    OverlayDrawer,
    InlineDrawer,
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  Drawer,
  DrawerProps,
  Button,
  Label,
  Radio,
  RadioGroup,
  makeStyles,
  tokens,
  useId,
  useRestoreFocusSource,
  useRestoreFocusTarget,
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";

export const UserProfile = ({isOpen, onClose}) => {
  const auth = useAuth();
    return (
        <OverlayDrawer
        as="aside"
        position="end"
        open={isOpen}
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular />}
                onClick={onClose}
              />
            }
          >
            User Profile
          </DrawerHeaderTitle>
        </DrawerHeader>

        <DrawerBody>
          <p>User Settings go here!</p>
          <p>{auth.user?.profile.sub}</p>
        </DrawerBody>
      </OverlayDrawer>
    )
};