import * as React from "react";
import { makeStyles, Button, tokens } from "@fluentui/react-components";
import logo from '../assets/images/vixo-link-logo.png';

const useClasses = makeStyles({
    iconImage: {width: "36px", height: "36px"},
    iconButton: {padding: "8px", paddingLeft:"12px", minWidth: "36px", backgroundColor: tokens.colorNeutralBackground5, border: "none"},
    appIcon: {width: "100%", height: "100%", backgroundColor: tokens.colorNeutralBackground5}
  });

export const AppIcon = (props) => {
    const classes = useClasses();
return (
  <div className={classes.appIcon}>
    <Button className={classes.iconButton}>
        <img src={logo} className={classes.iconImage} />
    </Button>
  </div>
)};