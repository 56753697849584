import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { makeStyles, shorthands, tokens, useClasses } from "@fluentui/react-components";
import { InternalApiClient } from 'providers/InternalApiClient';
import { Toolbar, ToolbarButton, ToolbarDivider, ToolbarGroup, Avatar, Label } from "@fluentui/react-components";
import {
    TableBody,
    TableCell,
    TableRow,
    Table,
    TableHeader,
    TableHeaderCell,
    TableCellLayout,
    PresenceBadgeStatus,
    Button,
    useArrowNavigationGroup,
    useFocusableGroup,
    SpinButton,
    Switch,
  } from "@fluentui/react-components";

const useClassesView = makeStyles({
  
    icon24: { fontSize: "24px" },
    icon32: { fontSize: "32px" },
    icon48: { fontSize: "48px" },

    headerButton: { marginRight:"5px", backgroundColor: tokens.colorNeutralBackground5},
    headerBar: {height:"42px", backgroundColor: tokens.colorNeutralBackground5, justifyContent: "space-between"},
    userMenuGroup: {
        float: "right",
    }
  });

  const columns = [
    { columnKey: "code", label: "Notification Code", idealWidth: 100, minWidth: 50, },
    { columnKey: "description", label: "Description", idealWidth: 300, minWidth: 100 },
    { columnKey: "isActive", label: "Active", idealWidth: 50, minWidth: 50 },
    { columnKey: "amount", label: "Amount", idealWidth: 50, minWidth: 50 },
  ];

const ViewRatePlanPage = () => {
    const { id } = useParams();
    const classes = useClassesView();
    const keyboardNavAttr = useArrowNavigationGroup({ axis: "grid" });
    const focusableGroupAttr = useFocusableGroup({
        tabBehavior: "limited-trap-focus",
      });
    const [items, setItems] = useState([]);

    const fetchCharges = () => {
        InternalApiClient.get(`api/rateplans/${id}/charges`)
            .then((data) => {
                setItems(data);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    useEffect(() => {
        fetchCharges();
    }, []);

    const handleActivate = (code) => (event) => {
        if (event.target.checked) {
            //setIsLoading(true);
            InternalApiClient.post(`/api/rateplans/${id}/activate/${code}`)
            .then((response) => {
                //setIsLoading(false);
                setItems(response);
            })
            .catch((error) => {
                console.error(error);
                //setError(error);
            });

        } else {
            InternalApiClient.post(`/api/rateplans/${id}/inactivate/${code}`)
            .then((response) => {
                //setIsLoading(false);
                setItems(response);
            })
            .catch((error) => {
                //setIsLoading(false);
                console.error(error);
                //setError(error);
            });

        }

        console.log(code);
    };

    const handlePriceChange = (code) => (event, data) => {
        console.log('price change');
        console.log(data);
        var price = data.value;
        if (!price) {
            price = Number(data.displayValue);
        }
        const changeRequest = {
            ratePlanId: id,
            notificationCode: code,
            price: price,
        };

        InternalApiClient.post(`/api/rateplans/updatecharge`, changeRequest)
            .then((response) => {
                setItems(response);
            });
        /*const updatedCharges = items.map(item => {
            if (item.notificationCode === code)
            {
                const changeRequest = {
                    ratePlanId: id,
                    notificationCode: code,
                    price: event.target.value;
                };
                item.amount = event.target.value;
            }
            return item;
        });*/

        //setItems(updatedCharges);
    };

    return (
        <>
        <Toolbar aria-label="Vertical" size="Large" className={classes.headerBar}>
            <Label className={classes.title}>Rate Plan Charges </Label>
        </Toolbar>
        <Table
            {...keyboardNavAttr}
            role="grid"
            aria-label="Table with grid keyboard navigation"
            >
            <TableHeader>
                <TableRow>
                {columns.map((column) => (
                    <TableHeaderCell key={column.columnKey}>
                    {column.label}
                    </TableHeaderCell>
                ))}
                </TableRow>
            </TableHeader>
            <TableBody>
                {items.map((item) => (
                    <TableRow key={item.id}>
                        <TableCell tabIndex={0} role="gridcell">
                            {item.notificationCode}
                        </TableCell>
                        <TableCell tabIndex={0} role="gridcell">
                            {item.description}
                        </TableCell>
                        <TableCell tabIndex={0} role="gridcell">
                            <TableCellLayout>
                                <Switch aria-label="Active" 
                                checked={item.isActive} onClick={handleActivate(item.notificationCode)} />
                            </TableCellLayout>
                        </TableCell>
                        <TableCell tabIndex={0} role="gridcell">
                            <TableCellLayout>
                                <SpinButton name="Amount"
                                precision="2"
                                aria-label="Amount" disabled={!item.isActive} value={item.amount} 
                                onChange={handlePriceChange(item.notificationCode)} />
                            </TableCellLayout>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
            </Table>
            </>
    )
};

export default ViewRatePlanPage;