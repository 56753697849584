import React, {useState, useEffect, useCallback} from 'react';
import { InternalApiClient } from 'providers/InternalApiClient';
import { Toolbar, ToolbarButton, ToolbarDivider, ToolbarGroup, Avatar, Label, Divider, Spinner } from "@fluentui/react-components";
import { makeStyles, shorthands, tokens, useClasses } from "@fluentui/react-components";
import {
    TableBody,
    TableCell,
    TableRow,
    Table,
    TableHeader,
    TableHeaderCell,
    TableCellLayout,
    PresenceBadgeStatus,
    useArrowNavigationGroup,
    useFocusableGroup,
    SpinButton,
    Switch,
  } from "@fluentui/react-components";

import {
    AddFilled,
    Dissmiss12Regular,
    DismissRegular,
} from "@fluentui/react-icons";
import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Input,
    Checkbox,
    useId,
    Field,
    Button,
  } from "@fluentui/react-components";
  import {
    MessageBar,
    MessageBarActions,
    MessageBarTitle,
    MessageBarBody,
    MessageBarGroup,
  } from "@fluentui/react-components";

const useClassesNav = makeStyles({
    icon24: { fontSize: "24px" },
    icon32: { fontSize: "32px" },
    icon48: { fontSize: "48px" },

    headerButton: { marginRight:"5px", backgroundColor: tokens.colorNeutralBackground4},
    headerBar: {height:"42px", backgroundColor: tokens.colorNeutralBackground4, justifyContent: "space-between"},
    userMenuGroup: {
        float: "right",
    },
    pageContent: {
        borderLeft: "1px gray solid",
        borderTop: "1px gray solid",
    },
    messageBarGroup: {
        padding: tokens.spacingHorizontalMNudge,
        display: "flex",
        flexDirection: "column",
        marginTop: "0px",
        gap: "10px",
        height: "40px",
        zIndex: 120,
        overflow: "auto",
        border: "1px solid blue",
    },
    messageBarHidden: {
        display: "none",
    },
    messageBarVisible: {
        display: "block",
    },
});

const columns = [
    { columnKey: "id", label: "Id", idealWidth: 100, minWidth: 50, },
    { columnKey: "name", label: "Name", idealWidth: 300, minWidth: 100 },
    { columnKey: "title", label: "Title", idealWidth: 50, minWidth: 50 },
    { columnKey: "email", label: "Email", idealWidth: 50, minWidth: 50 },
    { columnKey: "mobilePhone", label: "MobilePhone", idealWidth: 50, minWidth: 50 },
    { columnKey: "roles", label: "Roles", idealWidth: 50, minWidth: 50 },
    { columnKey: "status", label: "Status", idealWidth: 50, minWidth: 50 },
  ];

const TenantMembers = (current) => {
    const classes = useClassesNav();
    const [items, setItems] = useState([]);
    const [openAddMember, setOpenAddMember] = useState(false);
    const [messages, setMessages] = useState([]);
    const clearMessages = () => setMessages([]);
    const dismissMessage = (messageId) =>
        setMessages((s) => s.filter((entry) => entry.id !== messageId));
    const addError = (title, message) => {
        const id = Math.floor(Math.random());
        const newMessage = {
            id: id,
            title: title,
            message: message,
        };

        setMessages((s) => [newMessage, ...s]);
    };

    const fetchMembers = () => {
        InternalApiClient.get(`api/tenants/${current.tenant.id}/members`)
            .then((data) => {
                setItems(data);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    useEffect(() => {
        fetchMembers();
    }, []);

    const [submitting, setSubmitting] = useState(false);
    const handleAddMember = useCallback((ev) => {
        ev.preventDefault();
        if (submitting) {
            return;
        }

        setSubmitting(true);
        setMessages([]);
        const formData = new FormData(ev.target);
        const formProps = Object.fromEntries(formData);

        var roles = [];
        if (formProps.admin) {
            roles.push("admin");
        }
        if (formProps.owner) {
            roles.push("owner");
        }
        if (formProps.agent) {
            roles.push("agent");
        }

        const newMember = {
            name: formProps.name,
            email: formProps.email,
            mobilePhone: formProps.phone,
            roles: roles,
        };

        InternalApiClient.post(`/api/Tenants/${current.tenant.id}/member`, newMember)
        .then((data) => {
            setSubmitting(false)
            setOpenAddMember(false);
            fetchMembers();
            console.log(data);
        })
        .catch((error) => {
            setSubmitting(false)
            addError("Error Adding Member", error.response.data.detail || error.message);
            console.error(error);
        });
    }, [submitting]);

    return (
        <div className="body">
            <Toolbar aria-label="Vertical" size="Large" className={classes.headerBar}>
                <Label className={classes.title}>Tenant Members: {current.tenant.displayName} </Label>
                <ToolbarButton icon={<AddFilled />} onClick={() => setOpenAddMember(true)} className={classes.headerButton}>
                    Add Member
                </ToolbarButton>
            </Toolbar>
            <Dialog open={openAddMember} onOpenChange={(event, data) => setOpenAddMember(data.open)}>
                <DialogSurface>
                    <form onSubmit={handleAddMember}>
                        <DialogBody>
                            <DialogTitle>Add New Member</DialogTitle>
                            <DialogContent className={classes.dialogContent}>
                                <Field required placeholder="Member Name" label="Member Name">
                                    <Input required type="text" name="name" id={"name"} />
                                </Field>
                                <Field required label="Email Address">
                                    <Input required type="email" name="email" id={"email"} />
                                </Field>
                                <Field required label="Mobile Phone">
                                    <Input required type="phone" name="phone" id={"phone"} />
                                </Field>
                                <br/>
                                <Label>Security Roles</Label>
                                <Field hint="What security do you want to assign to this member?">
                                    <Checkbox name="owner" id="owner" value="owner" label="Owner" />
                                    <Checkbox name="admin" id="admin" value="admin" label="Admin" />
                                    <Checkbox name="agent" id="agent" value="agent" label="Agent" />
                                </Field>
                                {messages.length > 0 && (
                                    <>
                                        <br/>
                                        <MessageBarGroup classNames={classes.messageBarGroup + (messages.length > 0 ? classes.messageBarVisible : classes.messageBarHidden)}>
                                            {messages.map((message) => (
                                                <MessageBar key={message.id} intent="error">
                                                        <MessageBarBody>
                                                            <MessageBarTitle>{message.title}: </MessageBarTitle>
                                                            {message.message}
                                                        </MessageBarBody>
                                                    <MessageBarActions
                                                    containerAction={
                                                        <Button
                                                        onClick={() => dismissMessage(message.id)}
                                                        aria-label="dismiss"
                                                        appearance="transparent"
                                                        icon={<DismissRegular />}
                                                        />
                                                    }
                                                    />
                                                </MessageBar>
                                            ))}
                                        </MessageBarGroup>
                                    </>
                                )}
                            </DialogContent>
                            <DialogActions>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button appearance="secondary">Cancel</Button>
                                </DialogTrigger>
                                <Button type="submit" appearance="primary">
                                    {submitting && <Spinner size="tiny" style={{marginRight: '10px'}} />}
                                    Create
                                </Button>
                            </DialogActions>
                        </DialogBody>
                    </form>
                </DialogSurface>
            </Dialog>            
            <Table
                role="grid"
                aria-label="Table with grid keyboard navigation"
                >
                <TableHeader>
                    <TableRow>
                    {columns.map((column) => (
                        <TableHeaderCell key={column.columnKey}>
                        {column.label}
                        </TableHeaderCell>
                    ))}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {items.map((item) => (
                        <TableRow key={item.id}>
                            <TableCell tabIndex={0} role="gridcell">
                                {item.id}
                            </TableCell>
                            <TableCell tabIndex={0} role="gridcell">
                                {item.name}
                            </TableCell>
                            <TableCell tabIndex={0} role="gridcell">
                                {item.title}
                            </TableCell>
                            <TableCell tabIndex={0} role="gridcell">
                                {item.email}
                            </TableCell>
                            <TableCell tabIndex={0} role="gridcell">
                                {item.mobilePhone}
                            </TableCell>
                            <TableCell tabIndex={0} role="gridcell">
                                {item.roles}
                            </TableCell>
                            <TableCell tabIndex={0} role="gridcell">
                                {item.status}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                </Table>
            </div>
    )
};

export default TenantMembers;