import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import { InternalApiClient } from 'providers/InternalApiClient';
import { makeStyles, shorthands, tokens, typographyStyles } from "@fluentui/react-components";
import { Button } from "@fluentui/react-components";
import {
    AddFilled,
    DismissRegular,
    MoneyCalculatorFilled,
    MoreHorizontal20Regular,
  } from "@fluentui/react-icons";
import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Label,
    Input,
  } from "@fluentui/react-components";
  import {
    MessageBar,
    MessageBarActions,
    MessageBarTitle,
    MessageBarBody,
    MessageBarGroup,
    MessageBarIntent,
  } from "@fluentui/react-components";
  import {
    Card,
    CardFooter,
    CardHeader,
    CardPreview,
    Text,
    Caption1,
  } from "@fluentui/react-components";

import lightBackground from 'assets/images/backgrounds/visual_background_m365default_light.jpg';
import darkBackground from 'assets/images/backgrounds/visual_background_m365default_dark.jpg';

const useClasses = makeStyles({
    lightBackground: {
        backgroundImage: `url(${lightBackground})`,
        backgroundPosition: "50%",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },
    darkBackground: {
        backgroundImage: `url(${darkBackground})`,
        backgroundPosition: "50%",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },

    headerTitleName: {
        ...typographyStyles.title3,
    },
    headerTitleDescription: {
        ...typographyStyles.body,
    },
    emptyContainer: {
        textAlign: "center",
    },
    dialogContent: {
        display: "flex",
        flexDirection: "column",
        rowGap: "10px",
    },
    messageBarGroup: {
        padding: tokens.spacingHorizontalMNudge,
        display: "flex",
        flexDirection: "column",
        marginTop: "0px",
        gap: "10px",
        height: "40px",
        zIndex: 120,
        overflow: "auto",
        border: "1px solid blue",
    },
    messageBarHidden: {
        display: "none",
    },
    messageBarVisible: {
        display: "block",
    },
    cardsDiv: {
        padding: "10px",
        display: "flex",
        flexWrap: "wrap",
    },
    card: {
        margin: "10px",
        width: "360px",
        maxWidth: "100%",
        height: "fit-content",
        backgroundColor: tokens.colorBrandBackground2,
        ":hover": {
            backgroundColor: tokens.colorBrandBackground2Hover,
        }
    },
    cardIcon: {
        width: "60px",
        height: "60px",
    },
    caption: {
        color: tokens.colorNeutralForeground3,
    },
});

const RatePlanCard = ({ratePlan}) => {
    return (
        <p>{ratePlan.id}</p>
    )
}

const addRatePlan = ({})

const RatePlansHomePage = ({darkMode}) => {
    const classes = useClasses();
    const [isLoading, setIsLoading] = useState(true);
    const [ratePlans, setRatePlans] = useState([]);
    const [messages, setMessages] = useState([]);
    const clearMessages = () => setMessages([]);
    const dismissMessage = (messageId) =>
        setMessages((s) => s.filter((entry) => entry.id !== messageId));

    const addError = (title, message) => {
        const id = Math.floor(Math.random());
        const newMessage = {
            id: id,
            title: title,
            message: message,
        };

        setMessages((s) => [newMessage, ...s]);
    };

    const fetchRatePlans = () => {
        InternalApiClient.get("/api/rateplans")
        .then((response) => {
            setRatePlans(response);
            setIsLoading(false);
        })
        .catch((error) => {
            addError("Error Fetching Rate Plans", error.message);
            console.error(error);
        });
    };

    useEffect(() => {
        setIsLoading(true);
        clearMessages();
        fetchRatePlans();
    }, []);

    const handleAddSubmit = (ev) =>{
        ev.preventDefault();
        const formData = new FormData(ev.target);
        const formProps = Object.fromEntries(formData);

        const newRatePlan = {
            name: formProps.name,
            description: formProps.description,
        };

        InternalApiClient.post('/api/rateplans', newRatePlan)
        .then((data) => {
            fetchRatePlans();
        })
        .catch((error) => {
            addError("Error Adding Rate Plan", error.message);
            console.error(error);
        })
    };

    return (
        <>
        <div className={"areaPageContainer " + (darkMode ? classes.darkBackground : classes.lightBackground)}>
            <div className={"topContainer"}>
                <div className='actionHeaderContainer'>
                    <div className='title hidden-sm hidden-xs'>
                        <div className="fadedSection">
                            <label className={classes.headerTitleName}>Rate Plans</label>
                            <br/>
                            <label className={classes.headerTitleDescription}>Rate Plans determine the amount charged to each tenant per event. <br/>
                                High frequency events, at lower rates can actually produce more profit than lower frequency events at higher rates.
                            </label>
                        </div>
                    </div>
                    <div className='actions'>
                        <Dialog>
                            <DialogTrigger disableButtonEnhancement>
                                <Button icon={<AddFilled />}>Add New Plan</Button>
                            </DialogTrigger>
                            <DialogSurface>
                            <form onSubmit={handleAddSubmit}>
                                <DialogBody>
                                    <DialogTitle>Add New Rate Plan</DialogTitle>
                                    <DialogContent className={classes.dialogContent}>
                                        <Label required htmlFor={"name-input"}>
                                            Rate Plan Name
                                        </Label>
                                        <Input required type="text" name="name" id={"name-input"} />
                                        <Label required htmlFor={"description-input"}>
                                            Rate Plan Description
                                        </Label>
                                        <Input required type="text" name="description" id={"description-input"} />
                                    </DialogContent>
                                    <DialogActions>
                                        <DialogTrigger disableButtonEnhancement>
                                            <Button appearance="secondary">Cancel</Button>
                                        </DialogTrigger>
                                        <DialogTrigger disableButtonEnhancement>
                                            <Button type="submit" appearance="primary">
                                                Add
                                            </Button>
                                        </DialogTrigger>
                                    </DialogActions>
                                </DialogBody>
                            </form>
                            </DialogSurface>
                        </Dialog>
                    </div>
                </div>
            </div>
            <div className="bottomContainer">
                <MessageBarGroup classNames={classes.messageBarGroup + (messages.length > 0 ? classes.messageBarVisible : classes.messageBarHidden)}>
                    {messages.map((message) => (
                    <MessageBar key={message.id} intent="error">
                            <MessageBarBody>
                                <MessageBarTitle>{message.title}: </MessageBarTitle>
                                {message.message}
                            </MessageBarBody>
                        <MessageBarActions
                        containerAction={
                            <Button
                            onClick={() => dismissMessage(message.id)}
                            aria-label="dismiss"
                            appearance="transparent"
                            icon={<DismissRegular />}
                            />
                        }
                        />
                    </MessageBar>
                    ))}
                </MessageBarGroup>
                <div className="cardContainer">
                {isLoading && 
                    <p>Loading...</p>
                }
                {!isLoading && ratePlans.length == 0 &&
                    <div className={classes.emptyContainer}>
                        <p>No Rate Plans defined</p>
                    </div>
                }
                {!isLoading && ratePlans.length > 0 &&
                    <div className={classes.cardsDiv}>
                    {ratePlans.map((ratePlan) => (
                    <Link key={ratePlan.id} to={"/rateplans/" + ratePlan.id}>
                        <Card className={classes.card}>
                            <CardHeader
                            image={
                                <MoneyCalculatorFilled className={classes.cardIcon} />
                            }
                            header={<Text weight="semibold">{ratePlan.name}</Text>}
                            description={
                                <Caption1 className={classes.caption}>{ratePlan.description}</Caption1>
                            }
                            action={
                                <Button
                                appearance="transparent"
                                icon={<MoreHorizontal20Regular />}
                                aria-label="More options"
                                />
                            }
                            />
                            <p>
                            List of billing accounts goes here
                            </p>
                        </Card>
                    </Link>
                    ))}
                    </div>
                }
                </div>
            </div>
        </div>
        </>
    );
};

export default RatePlansHomePage;