import * as React from "react";
import {
    SettingsRegular,
    AlertRegular,
} from "@fluentui/react-icons";
import { makeStyles, shorthands, tokens, typographyStyles } from "@fluentui/react-components";
import { Toolbar, ToolbarButton, ToolbarDivider, ToolbarGroup, Avatar, Label } from "@fluentui/react-components";

const useClasses = makeStyles({
    container: {
      display: "flex",
      ...shorthands.gap("5px"),
    },
    title: {
        ...typographyStyles.subtitle2,
    },
  
    icon24: { fontSize: "24px" },
    icon32: { fontSize: "32px" },
    icon48: { fontSize: "48px" },

    headerButton: { marginRight:"5px", backgroundColor: tokens.colorNeutralBackground5},
    headerBar: {height:"42px", backgroundColor: tokens.colorNeutralBackground5, justifyContent: "space-between"},
    userMenuGroup: {
        float: "right",
    }
  });

export const HeaderBar = ({props, onShowUserProfile, onShowSettings}) => {
    const classes = useClasses();
    return (
  <Toolbar {...props} aria-label="Vertical" size="Large" className={classes.headerBar}>
    <ToolbarGroup>
        <Label className={classes.title}>Vixo-Link Internal</Label>
    </ToolbarGroup>
    <ToolbarGroup className={classes.userMenuGroup}>
        <ToolbarButton icon={<AlertRegular />} className={classes.headerButton} />
        <ToolbarButton icon={<SettingsRegular />} className={classes.headerButton} onClick={onShowSettings}  />
        <Avatar color="brand" className={classes.headerButton} onClick={onShowUserProfile} />
    </ToolbarGroup>
  </Toolbar>
)};