import React, { useState } from 'react';

import PlaidNav from './Nav';
import PlaidHomePage from './Home';

const Views = {
    MainPage: "Main",
    Customers: "Customers",
};

const PlaidMainPage = () => {
    const [currentPage, setCurrentPage] = useState(Views.MainPage);

    return (
        <div class="twoColumnContainer">
            <nav class="nav">
                <PlaidNav currentPage={currentPage} setCurrentPage={() => setCurrentPage} />
            </nav>
            <nav class="body">
                <PlaidHomePage />
            </nav>
        </div>
    )
};

export default PlaidMainPage;