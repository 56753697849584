import React from 'react';
import { makeStyles, shorthands, tokens, typographyStyles } from "@fluentui/react-components";
import lightBackground from 'assets/images/backgrounds/visual_background_m365default_light.jpg';
import darkBackground from 'assets/images/backgrounds/visual_background_m365default_dark.jpg';
import {
    BuildingFilled,
    CalculatorFilled,
    PersonMoneyFilled,
  } from "@fluentui/react-icons";

import { Card, CardHeader, CardPreview, Text, Caption1 } from "@fluentui/react-components";
import ShortcutCard from 'components/Shortcut';

const useClasses = makeStyles({
    lightBackground: {
        backgroundImage: `url(${lightBackground})`,
        backgroundPosition: "50%",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },
    darkBackground: {
        backgroundImage: `url(${darkBackground})`,
        backgroundPosition: "50%",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },

    icon64: 
    { 
        fontSize: "48px",
    },

    caption: {
        color: tokens.colorNeutralForeground3,
    },

    card: {
        width: "300px",
        backgroundColor: "rgba(100, 100, 100, 0.5)",
    }
});


const HomePage = ({darkMode}) => {
    const classes = useClasses();
    console.log(darkMode);
    return (
        <div className={"homePageContainer " + (darkMode ? classes.darkBackground : classes.lightBackground)}>
            <div className={"topContainer"}>
                <div className="cardContainer">
                    <ShortcutCard title="View Rate Plans" linkTo="/rateplans"
                        description="Rate Plans determine how much to charge for any of the events in the system."
                        icon={<CalculatorFilled className={classes.icon64}/>} />

                    <ShortcutCard title="View Billing Accounts" linkTo="/billing"
                        description="Each of Vixo-Link's customers require a Billing Account that will track their usage."
                        icon={<BuildingFilled className={classes.icon64}/>} />
                </div>
            </div>
            <div className="bottomContainer">
                
            </div>
        </div>
    );
};

export default HomePage;