import {create} from "zustand";

const userStore = (set, get) => ({
    email: "",
    setUser: (user) => {
        set((state) => ({
            email: user?.profile?.sub
        }));
    },
});

const useUserStore = create(userStore);

export default useUserStore;