import React, { useState, useEffect } from 'react';
import { InternalApiClient } from 'providers/InternalApiClient';
import { makeStyles, shorthands, tokens, typographyStyles } from "@fluentui/react-components";
import masterCard from 'assets/images/logos/mastercard.png';

const useClasses = makeStyles({
    container: {
        padding: "25px",
    },
});

const FinicityHomePage = (subscriptions) => {
    const classes = useClasses();
    
    return (
        <div className={classes.container}>
            {subscriptions &&
            <ul role="list" className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
            {subscriptions.subscriptions.map((subscription) => (
                <li key={subscriptions.id} className="overflow-hidden rounded-xl border border-gray-200">
                    <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                    <img src={masterCard} alt="Finicity" className="h-5 w-9 flex-none rounded-ee-lg object-cover ring-1 ring-gray-900/10" />
                    <div className="text-sm font-medium leading-6 text-gray-900">{subscription.displayName}</div>
                        <div className="relative ml-auto">
                            <button type="button" className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500" id="options-menu-0-button" aria-expanded="false" aria-haspopup="true">
                            <span className="sr-only">Open options</span>
                            <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path d="M3 10a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM8.5 10a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM15.5 8.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3z" />
                            </svg>
                            </button>
                        </div>
                    </div>
                    <dl className="-my-3 px-6 py-4 text-sm leading-6">
                        <div className="flex justify-between gap-x-4 py-3">
                            <dt className="text-gray-500">Partner ID</dt>
                            <dd className="text-gray-500">{subscription.partnerId}</dd>
                        </div>
                        <div className="flex justify-between gap-x-4 py-3">
                            <dt className="text-gray-500">Partner Secret</dt>
                            <dd className="text-gray-500">{subscription.partnerSecret}</dd>
                        </div>
                        <div className="flex justify-between gap-x-4 py-3">
                            <dt className="text-gray-500">App Key</dt>
                            <dd className="text-gray-500">{subscription.appKey}</dd>
                        </div>
                    </dl>
                </li>
            ))}
            </ul>
            }
        </div>
    )
};

export default FinicityHomePage;