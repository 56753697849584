import React, { useState } from "react";
import {
    OverlayDrawer,
    InlineDrawer,
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  Switch,
  Button,
  Label,
  Radio,
  RadioGroup,
  makeStyles,
  tokens,
  useId,
  useRestoreFocusSource,
  useRestoreFocusTarget,
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";

export const Settings = ({isOpen, onClose, useDarkMode, onSwitchDarkMode}) => {

    return (
        <OverlayDrawer
        as="aside"
        position="end"
        open={isOpen}
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular />}
                onClick={onClose}
              />
            }
          >
            Settings
          </DrawerHeaderTitle>
        </DrawerHeader>
        <Switch
          checked={useDarkMode}
          onChange={onSwitchDarkMode}
          label="Dark Mode"
        />
        <DrawerBody>
          <p>User Settings go here!</p>
        </DrawerBody>
      </OverlayDrawer>
    )
};