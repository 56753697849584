import React, { useState, useEffect } from 'react';
import { makeStyles, shorthands, tokens, useClasses } from "@fluentui/react-components";
import { Toolbar, ToolbarButton, ToolbarDivider, ToolbarGroup, Avatar, Label } from "@fluentui/react-components";
import { InternalApiClient } from 'providers/InternalApiClient';
import {
    DeleteFilled,
} from "@fluentui/react-icons";
import {
    TableBody,
    TableCell,
    TableRow,
    Table,
    TableHeader,
    TableHeaderCell,
    TableCellLayout,
    PresenceBadgeStatus,
    Button,
    useArrowNavigationGroup,
    useFocusableGroup,
  } from "@fluentui/react-components";

  const columns = [
    { columnKey: "id", label: "ID" },
    { columnKey: "username", label: "UserName" },
    { columnKey: "firstName", label: "First Name" },
    { columnKey: "created", label: "Created" },
    { columnKey: "actions", label: "Actions" },
  ];

const useClasses2 = makeStyles({
  
    icon24: { fontSize: "24px" },
    icon32: { fontSize: "32px" },
    icon48: { fontSize: "48px" },

    headerButton: { marginRight:"5px", backgroundColor: tokens.colorNeutralBackground5},
    headerBar: {height:"42px", backgroundColor: tokens.colorNeutralBackground5, justifyContent: "space-between"},
    userMenuGroup: {
        float: "right",
    }
  });

const FinicityCustomersPage = (subscription) => {
    const classes = useClasses2();
    const keyboardNavAttr = useArrowNavigationGroup({ axis: "grid" });
    const focusableGroupAttr = useFocusableGroup({
        tabBehavior: "limited-trap-focus",
      });
    const [items, setItems] = useState([]);
    const [total, setTotal] = useState(0);

    const fetchCustomers = () => {
        InternalApiClient.get(`api/finicity/customers/${subscription.subscription.id}?start=1&limit=25`)
            .then((data) => {
                setItems(data.customers);
                setTotal(data.found);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    useEffect(() => {
        fetchCustomers();
    }, []);

    function deleteCustomer(item) {
        console.log(item);
        InternalApiClient.delete(`api/finicity/customers/${subscription.subscription.id}/${item.id}`)
            .then(() => {
                fetchCustomers();
            })
            .catch((error) => {
                console.error(error);
            });
    };
    
    return (
        <>
        <Toolbar aria-label="Vertical" size="Large" className={classes.headerBar}>
            <Label className={classes.title}>Finicity {subscription.subscription.displayName} Customers ({total}) </Label>
            <ToolbarButton icon={<DeleteFilled />} className={classes.headerButton} />
        </Toolbar>
        <Table
            {...keyboardNavAttr}
            role="grid"
            aria-label="Table with grid keyboard navigation"
            style={{ minWidth: "620px" }}
            >
            <TableHeader>
                <TableRow>
                {columns.map((column) => (
                    <TableHeaderCell key={column.columnKey}>
                    {column.label}
                    </TableHeaderCell>
                ))}
                </TableRow>
            </TableHeader>
            <TableBody>
                {items.map((item) => (
                    <TableRow key={item.id}>
                        <TableCell tabIndex={0} role="gridcell">
                            {item.id}
                        </TableCell>
                        <TableCell tabIndex={0} role="gridcell">
                            {item.username}
                        </TableCell>
                        <TableCell tabIndex={0} role="gridcell">
                            {item.firstName}
                        </TableCell>
                        <TableCell tabIndex={0} role="gridcell">
                            {item.createdDate}
                        </TableCell>
                        <TableCell role="gridcell" tabIndex={0} {...focusableGroupAttr}>
                            <TableCellLayout>
                                <Button onClick={() => deleteCustomer(item)} icon={<DeleteFilled />} aria-label="Delete" />
                            </TableCellLayout>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
            </Table>
        </>
    )
};

export default FinicityCustomersPage;