import React from 'react';
import { makeStyles, shorthands, tokens, typographyStyles } from "@fluentui/react-components";

const useClasses = makeStyles({
    
});

const PlaidHomePage = () => {
    return (
        <p>Plaid page goes here</p>
    )
};

export default PlaidHomePage;