import * as React from "react";
import { useLocation, NavLink } from "react-router-dom";
import {
  SendFilled,
  HomeFilled,
  BuildingRetailMoneyFilled,
  MoneyCalculatorFilled,
  PersonMoneyFilled,
} from "@fluentui/react-icons";
import { makeStyles, shorthands, tokens, ToolbarGroup, typographyStyles } from "@fluentui/react-components";
import { Toolbar, ToolbarButton, ToolbarDivider, Button, Link, Divider } from "@fluentui/react-components";

import masterCard from '../assets/images/logos/mastercard.png';
import plaid from '../assets/images/logos/plaid.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useClasses = makeStyles({
    toolbar: {
        justifyContent: "space-between",
    },
    container: {
      display: "flex",
      ...shorthands.gap("5px"),
    },
  
    icon24: { fontSize: "24px" },
    icon32: 
    { 
        fontSize: "32px",
        color: tokens.colorNeutralForeground2,
        ":hover": {
            color: tokens.colorCompoundBrandBackgroundHover,
        }
    },
    icon48: { fontSize: "48px" },

    buttonLabel: {
        ...typographyStyles.caption2,
        paddingTop: "0",
        width: "100%",
        color: tokens.colorNeutralForeground2,
    },
    buttonIcon: {
        width: "32px", 
        margin: "auto",
        ":hover": {
            color: tokens.colorCompoundBrandBackgroundHover,
        }
    },
    appBarButton: {
        margin: 0, 
        paddingTop: "5px", 
        minWidth: "60px", 
        maxHeight: "80px",
        marginTop: "5px",
        marginBottom: "5px",
        alignItems: "center", 
        textAlign: "center", 
        minHeight: "48px", 
        backgroundColor: tokens.colorNeutralBackground5,
        border:"none",
        cursor: "pointer",
        ":hover": {
            backgroundColor: tokens.colorNeutralBackground5Hover,
        }
    },
    appBar: {
        margin: 0, 
        padding: 0,
        height: "100%", 
        backgroundColor: tokens.colorNeutralBackground5,
        justifyContent: "space-between",
        marginBottom: "20px",
    },
    appBarDivider: {
        color: tokens.colorNeutralBackground5,
        border: 0,
        marginTop: "10px",
        marginBottom: "10px"
    },
  });

export const AppBar = (props) => {
    const classes = useClasses();
return (
  <Toolbar className={classes.appBar} vertical {...props} 
    aria-label="Vertical" size="large">
    <ToolbarGroup>
        <NavLink to="/" key="Home" sx={{textDecoration: "none"}}>
            <button className={classes.appBarButton}>
                <HomeFilled className={classes.icon32} /><br/>
                <label className={classes.buttonLabel}>Home</label>
            </button>
        </NavLink>
        <NavLink to="/RatePlans" key="RatePlans" sx={{textDecoration: "none"}}>
            <button className={classes.appBarButton}>
                <MoneyCalculatorFilled className={classes.icon32} /><br/>
                <label className={classes.buttonLabel}>Rate Plans</label>
            </button>
        </NavLink>
        <NavLink to="/Billing" key="Billing" sx={{textDecoration: "none"}}>
            <button className={classes.appBarButton}>
                <PersonMoneyFilled className={classes.icon32} /><br/>
                <label className={classes.buttonLabel}>Billing Accounts</label>
            </button>
        </NavLink>
        <ToolbarDivider className={classes.appBarDivider} />
        <NavLink to="/finicity" key="Finicity" sx={{textDecoration: "none"}}>
            <button shape="square" className={classes.appBarButton}>
                <img src={masterCard} className={classes.buttonIcon} /><br/>
                <label className={classes.buttonLabel}>Finicity</label>
            </button>
        </NavLink>
        <NavLink to="/plaid" key="Plaid" sx={{textDecoration: "none"}}>
            <button shape="square" className={classes.appBarButton}>
                <img src={plaid} className={classes.buttonIcon} /><br/>
                <label className={classes.buttonLabel}>Plaid</label>
            </button>
        </NavLink>
    </ToolbarGroup>
  </Toolbar>
)};