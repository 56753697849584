import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { InternalApiClient } from 'providers/InternalApiClient';
import BillingNav from './Nav';
import BillingAccountDashboard from './Dashboard';
import TenantMembers from './Members';
import TenantNotifications from './Notifications';

import {
    MessageBar,
    MessageBarActions,
    MessageBarTitle,
    MessageBarBody,
    MessageBarGroup,
    Button,
  } from "@fluentui/react-components";
import { makeStyles, shorthands, tokens, useClasses } from "@fluentui/react-components";
import {
    AddFilled,
    Dissmiss12Regular,
    DismissRegular,
} from "@fluentui/react-icons";

const useClasses2 = makeStyles({
    pageContent: {
        borderLeft: "1px gray solid",
        borderTop: "1px gray solid",
    },
    messageBarGroup: {
        padding: tokens.spacingHorizontalMNudge,
        display: "flex",
        flexDirection: "column",
        marginTop: "0px",
        gap: "10px",
        height: "40px",
        zIndex: 120,
        overflow: "auto",
        border: "1px solid blue",
    },
    messageBarHidden: {
        display: "none",
    },
    messageBarVisible: {
        display: "block",
    },
  });

const ViewBillingPage = () => {
    const classes = useClasses2();
    const { id } = useParams();
    const [tenants, setTenants] = useState([]);
    const [currentPage, setCurrentPage] = useState("Main");
    const [currentTenant, setCurrentTenant] = useState(null);
    const [messages, setMessages] = useState([]);
    const clearMessages = () => setMessages([]);
    const dismissMessage = (messageId) =>
        setMessages((s) => s.filter((entry) => entry.id !== messageId));
    const addError = (title, message) => {
        const id = Math.floor(Math.random());
        const newMessage = {
            id: id,
            title: title,
            message: message,
        };

        setMessages((s) => [newMessage, ...s]);
    };

    const getPageContent = (pageIndex) => {
        switch(pageIndex) {
            case "Main":
                return <BillingAccountDashboard />;
            case "Members":
                return <TenantMembers tenant={currentTenant} />;
        }
    }

    const setSelectedPage = (page) => {
        console.log(page);
        if (page.startsWith("MEMBERS:TNT-"))
        {
            var tenantId = page.substring(8);
            var matchingTenants = tenants.filter(item => item.id === tenantId);
            if (matchingTenants) {
                setCurrentPage("Members");
                setCurrentTenant(matchingTenants[0]);
            }
        } else {
            setCurrentPage(page);
        }
    }

    const fetchTenants = () => {
        InternalApiClient.get('/api/tenants/account/' + id)
        .then((data) => {
            setTenants(data);
            console.log(data);
        })
        .catch((error) => {
            addError("Error Fetching Tenants", error.message);
        });
    };

    useEffect(() => {
        fetchTenants();
    }, []);

    return (
        <div className="twoColumnContainer">
            <nav className="nav">
                <BillingNav
                    currentPage={currentPage}
                    setCurrentPage={(page) => setSelectedPage(page)}
                    tenants={tenants}/>
            </nav>
            <div className="body">
                <div>
                <MessageBarGroup classNames={classes.messageBarGroup + (messages.length > 0 ? classes.messageBarVisible : classes.messageBarHidden)}>
                    {messages.map((message) => (
                    <MessageBar key={message.id} intent="error">
                            <MessageBarBody>
                                <MessageBarTitle>{message.title}: </MessageBarTitle>
                                {message.message}
                            </MessageBarBody>
                        <MessageBarActions
                        containerAction={
                            <Button
                            onClick={() => dismissMessage(message.id)}
                            aria-label="dismiss"
                            appearance="transparent"
                            icon={<DismissRegular />}
                            />
                        }
                        />
                    </MessageBar>
                    ))}
                </MessageBarGroup>
                    {getPageContent(currentPage)}
                </div>
            </div>
        </div>
    )
};

export default ViewBillingPage;