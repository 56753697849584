import React, {useState, useEffect} from 'react';
import { InternalApiClient } from 'providers/InternalApiClient';

import FinicityNav from './Nav';
import FinicityHomePage from './Home';
import FinicityCustomersPage from './Customers';


function getPages() {
    return ["Main", "Customers"];
}

const FinicityMainPage = () => {
    const [currentPage, setCurrentPage] = useState("Main");
    const [subscriptions, setSubscriptions] = useState([]);
    const [currentSubscription, setCurrentSubscription] = useState([]);

    useEffect(() => {
        InternalApiClient.get('/api/subscriptions/finicity')
        .then((data) => {
            setSubscriptions(data);
        })
        .catch((error) => {
            console.log(error);
        });
    }, []);

    const setSelectedPage = (page) => {
        console.log(page);
        if (page.startsWith("CUSTOMERS:"))
        {
            var subscriptionId = page.substring(10);
            var matchingSubscriptions = subscriptions.filter(item => item.id === subscriptionId);
            if (matchingSubscriptions) {
                setCurrentSubscription(matchingSubscriptions[0]);
                setCurrentPage("Customers");
            }
        } else {
            setCurrentPage(page);
        }
    }

    function getPageContent(pageIndex) {
        console.log('get page');
        console.log(pageIndex);
        switch(pageIndex) {
            case "Main":
                return <FinicityHomePage subscriptions={subscriptions} />;
            case "Customers":
                return <FinicityCustomersPage subscription={currentSubscription} />;
        }
    }

    return (
        <div className="twoColumnContainer">
            <nav className="nav">
                <FinicityNav subscriptions={subscriptions} currentPage={currentPage} setCurrentPage={(page) => setSelectedPage(page)} />
            </nav>
            <div className="body">
                {getPageContent(currentPage)}
            </div>
        </div>
    )
};

export default FinicityMainPage;